import serve from './serve'

export function login(params){//登陆
    return serve({
        url:'v1/login/index',
        method :'post',
        data:params,
    })
}

export function register(params){//注册
    return serve({
        url:'v1/login/register',
        method :'post',
        data:params,
    })
}

export function captcha(params){//验证码
    return serve({
        url:'v1/login/captcha',
        method :'get',
        params:params,
    })
}

export function loginChat(params){//登陆聊天室
    return serve({
        url:'v1/login/loginChat',
        method :'post',
        data:params,
    })
}



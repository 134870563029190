import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-301633fc"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "tk-container-wrap"
};
const _hoisted_2 = {
  class: "tk-wrap-title"
};
const _hoisted_3 = {
  class: "tk-wrap"
};
const _hoisted_4 = {
  class: "num"
};
import { onBeforeMount } from "vue";

import { reactive, toRefs, computed } from 'vue';
export default {
  __name: 'sx',
  setup(__props) {
    onBeforeMount(() => {
      data.codeData = JSON.parse(localStorage.getItem('number_attr2')).shengxiaoList;
    });
    let data = reactive({
      codeData: []
    });
    let {
      codeData
    } = toRefs(data);
    let styles = computed(() => item => {
      //计算属性传递参数
      return colorarray[cons(item, numarrays)];
    });
    let numarrays = [[1, 2, 7, 8, 12, 13, 18, 19, 23, 24, 29, 30, 34, 35, 40, 45, 46], [3, 4, 9, 10, 14, 15, 20, 25, 26, 31, 36, 37, 41, 42, 47, 48], [5, 6, 11, 16, 17, 21, 22, 27, 28, 32, 33, 38, 39, 43, 44, 49]];
    let cons = (d, array) => {
      let b = 0;
      for (let i = 0; i < array.length; i++) {
        for (let j = 0; j < array[i].length; j++) {
          if (array[i][j] == d) {
            b = i;
          }
        }
      }
      return b;
    };
    let colorarray = ['red', 'blue', 'green'];
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(codeData), (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          class: "tk-top-wrap flex",
          key: index
        }, [_createElementVNode("div", _hoisted_2, _toDisplayString(item.name), 1), _createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.list, items => {
          return _openBlock(), _createElementBlock("div", {
            class: _normalizeClass('color-wrap ' + _unref(styles)(items.number)),
            key: items
          }, [_createElementVNode("div", _hoisted_4, _toDisplayString(items.number), 1)], 2);
        }), 128))])]);
      }), 128))])]);
    };
  }
};




export function Waterfall(opt){
 
    this.el = document.getElementsByClassName(opt.el)[0];
 
    if(this.el ==undefined) return false
    if(this.el.getElementsByClassName('item')==undefined) return false
    this.oItems = this.el.getElementsByClassName('item')
    this.colmun = opt.colmun;
    this.gap = opt.gap;
    this.itemWidht = (this.el.offsetWidth-(this.colmun-1) * this.gap)/this.colmun
    this.heightArray = []
    this.init();

}

Waterfall.prototype.init = function(){
    this.render()
}
Waterfall.prototype.render = function(){
    var item = null
    minIdx = -1
    for(var i = 0; i<this.oItems.length;i++){
        item = this.oItems[i];
        item.style.width =  this.itemWidht + 'px'

        if(i<this.colmun){
            item.style.position = 'absolute'
            item.style.top = 0+'px';
            item.style.left = i * (this.itemWidht + this.gap) + 'px';
            this.heightArray.push(item.offsetHeight)
        }else{
          var minIdx = getMinIdex(this.heightArray);
          item.style.position = 'absolute'
          item.style.left = this.oItems[minIdx].offsetLeft+'px';
          item.style.top =this.heightArray[minIdx]+this.gap+'px';
          this.heightArray[minIdx] +=  item.offsetHeight + this.gap
        }
    }
    if(item ==undefined) return false
    let hegiht = item.offsetTop +  item.offsetHeight;
    this.el.style.height =hegiht+'px'
    function getMinIdex(array){
        return array.indexOf(Math.min.apply(null,array))
    }
}
import serve from './serve';

export function release(params){//获取用户信息
    return serve({
        url:'v1/user/release',
        method :'get',
        params:params,
    })
}

export function setBlacklist(params){//用户拉黑
    return serve({
        url:'v1/user/setBlacklist',
        method :'post',
        params:params,
    })
}

export function complaintAdd(params){//用户拉黑
    return serve({
        url:'v1/complaint/add',
        method :'post',
        params:params,
    })
}



import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, isRef as _isRef, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5c1f88ae"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "tk-grade-integral"
};
const _hoisted_2 = {
  class: "tk-my-integral"
};
const _hoisted_3 = {
  key: 1
};
const _hoisted_4 = {
  class: "intergral-r"
};
const _hoisted_5 = {
  key: 0,
  class: "text"
};
const _hoisted_6 = {
  key: 1,
  class: "text"
};
const _hoisted_7 = {
  key: 2,
  class: "text"
};
const _hoisted_8 = {
  key: 3,
  class: "text"
};
const _hoisted_9 = {
  key: 4,
  class: "text"
};
const _hoisted_10 = {
  class: "times"
};
const _hoisted_11 = {
  class: "intergral-l"
};
import { getGrowthScore } from '@/utils/https/me';
import { reactive } from 'vue';

import { onBeforeMount, ref } from 'vue';
export default {
  __name: 'integral',
  setup(__props) {
    let data = reactive({
      list: [],
      page: 1,
      growth_score: 0
    });
    onBeforeMount(() => {});
    let getGrowthScores = page => {
      getGrowthScore({
        page: page
      }).then(res => {
        if (res == undefined) return false;
        let datas = res.data.data.list;
        data.growth_score = res.data.data.growth_score;
        if (datas.length == 0) {
          finished.value = true;
        } else {
          loading.value = false;
          data.list.push(...datas);
        }
      });
    };
    const loading = ref(false);
    const finished = ref(false);
    const onLoad = () => {
      getGrowthScores(data.page);
      data.page++;
    };
    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      const _component_empty = _resolveComponent("empty");
      const _component_van_list = _resolveComponent("van-list");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: "我的成长值"
      }), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, "当前成长值：" + _toDisplayString(_unref(data).growth_score), 1), _createVNode(_component_van_list, {
        loading: _unref(loading),
        "onUpdate:loading": _cache[0] || (_cache[0] = $event => _isRef(loading) ? loading.value = $event : null),
        finished: _unref(finished),
        "finished-text": "",
        "loading-text": " ",
        onLoad: onLoad
      }, {
        default: _withCtx(() => [_unref(data).list.length == 0 ? (_openBlock(), _createBlock(_component_empty, {
          key: 0
        })) : (_openBlock(), _createElementBlock("ul", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(data).list, (item, index) => {
          return _openBlock(), _createElementBlock("li", {
            key: index
          }, [_createElementVNode("div", _hoisted_4, [item.type == 1 ? (_openBlock(), _createElementBlock("div", _hoisted_5, "一级拉新")) : _createCommentVNode("", true), item.type == 2 ? (_openBlock(), _createElementBlock("div", _hoisted_6, "二级拉新")) : _createCommentVNode("", true), item.type == 3 ? (_openBlock(), _createElementBlock("div", _hoisted_7, "发帖")) : _createCommentVNode("", true), item.type == 4 ? (_openBlock(), _createElementBlock("div", _hoisted_8, "回帖")) : _createCommentVNode("", true), item.type == 5 ? (_openBlock(), _createElementBlock("div", _hoisted_9, "点赞")) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_10, _toDisplayString(item.updated_at), 1)]), _createElementVNode("div", _hoisted_11, "+" + _toDisplayString(item.score), 1)]);
        }), 128))]))]),
        _: 1
      }, 8, ["loading", "finished"])])], 64);
    };
  }
};
import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue";
export default {
  __name: 'App',
  setup(__props) {
    // watch: {
    //     $route(to, from) {
    //       // if the route changes...
    //       let token = localStorage.getItem("court-token") || "";
    //       if (token) {
    //         // firebase returns null if user logged out
    //         this.isLoggedIn = true;
    //       } else {
    //         this.isLoggedIn = false;
    //       }
    //     },
    //   },

    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createBlock(_component_router_view, null, {
        default: _withCtx(({
          Component
        }) => [(_openBlock(), _createBlock(_KeepAlive, null, [_ctx.$route.meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
          key: _ctx.$route.name
        })) : _createCommentVNode("", true)], 1024)), !_ctx.$route.meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
          key: _ctx.$route.name
        })) : _createCommentVNode("", true)]),
        _: 1
      });
    };
  }
};
import "core-js/modules/es.array.push.js";
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6d90c1cf"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "tk-mian-type"
};
const _hoisted_2 = ["id"];
const _hoisted_3 = {
  class: "tk-type-list"
};
const _hoisted_4 = ["onClick"];
const _hoisted_5 = {
  class: "tk-name"
};
const _hoisted_6 = {
  class: "tk-num"
};
const _hoisted_7 = {
  class: "tk-type-string"
};
const _hoisted_8 = ["href"];
import search from '@/components/page/search.vue';
import { cate } from '@/utils/https/page';
import { useStore } from 'vuex';

import { useRoute, useRouter } from 'vue-router';
import { reactive, onBeforeMount, ref, onDeactivated, onActivated } from 'vue';
export default {
  __name: 'serach',
  setup(__props) {
    const route = useRoute();
    const router = useRouter();
    let datas = reactive({
      page: 1,
      year: localStorage.getItem('yearone'),
      lottery: localStorage.getItem('lotteryType'),
      color: '1',
      list: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
      indexList: []
    });
    let pictureCate = (year, color, lottery, keyword = '') => {
      cate({
        year: year,
        color: color,
        lotteryType: lottery,
        keyword: keyword
      }).then(res => {
        let masonry = [{
          letter: 'A',
          dataList: []
        }, {
          letter: 'B',
          dataList: []
        }, {
          letter: 'C',
          dataList: []
        }, {
          letter: 'D',
          dataList: []
        }, {
          letter: 'E',
          dataList: []
        }, {
          letter: 'F',
          dataList: []
        }, {
          letter: 'G',
          dataList: []
        }, {
          letter: 'H',
          dataList: []
        }, {
          letter: 'I',
          dataList: []
        }, {
          letter: 'J',
          dataList: []
        }, {
          letter: 'K',
          dataList: []
        }, {
          letter: 'L',
          dataList: []
        }, {
          letter: 'M',
          dataList: []
        }, {
          letter: 'N',
          dataList: []
        }, {
          letter: 'O',
          dataList: []
        }, {
          letter: 'P',
          dataList: []
        }, {
          letter: 'Q',
          dataList: []
        }, {
          letter: 'R',
          dataList: []
        }, {
          letter: 'S',
          dataList: []
        }, {
          letter: 'T',
          dataList: []
        }, {
          letter: 'U',
          dataList: []
        }, {
          letter: 'V',
          dataList: []
        }, {
          letter: 'W',
          dataList: []
        }, {
          letter: 'X',
          dataList: []
        }, {
          letter: 'Y',
          dataList: []
        }, {
          letter: 'Z',
          dataList: []
        }];
        let data = res.data.data;
        masonry.forEach((c, d) => {
          data.forEach((item, i) => {
            if (item.letter == c.letter) {
              masonry[d].dataList.push(item);
            }
          });
        });
        datas.indexList = masonry;
      });
    };
    onBeforeMount(() => {
      // if(JSON.stringify(route.query) != "{}"){
      //     datas.color = route.query.color;
      //     datas.year = route.query.year;
      // }
      // pictureCate(datas.year,datas.color,datas.lottery);
      window.addEventListener('scroll', onScroll);
    });
    const scroll = ref(null);
    const activeStep = ref();
    let onScroll = () => {
      let scrollItems = document.querySelectorAll('.tk-type-head');
      const scrollTop = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop;
      for (let i = scrollItems.length - 1; i >= 0; i--) {
        // 判断滚动条滚动距离是否大于当前滚动项可滚动距离
        let judge = scrollTop >= scrollItems[i].offsetTop - 44; //
        if (judge) {
          activeStep.value = i;
          break;
        }
      }
    };
    let colors = index => {
      datas.color = index;
      pictureCate(datas.year, index, datas.lottery);
    };
    let onSubmit = data => {
      pictureCate(datas.year, datas.color, datas.lottery, data);
    };
    let majorDesc = items => {
      router.push({
        path: `/gallery`,
        query: {
          color: items.color,
          year: items.year,
          id: items.pictureTypeId,
          title: items.pictureName
        }
      });
    };
    const $store = useStore(); //获取vuex
    onDeactivated(() => {
      $store.commit('handleSearch', false);
    });
    onActivated(() => {
      datas.lottery = localStorage.getItem('lotteryType');
      if (route.query.year == undefined) {
        var id = '2024';
      } else {
        var id = route.query.year;
      }
      if (route.query.color == undefined) {
        var ids = 1;
      } else {
        var ids = route.query.color;
      }
      if ($store.state.search) {
        if (id != datas.id || ids != datas.color) {
          datas.indexList.length = 0;
          if (JSON.stringify(route.query) != "{}") {
            datas.color = route.query.color;
            datas.year = route.query.year;
          } else {
            datas.color = 1;
            datas.year = '2024';
          }
          pictureCate(datas.year, datas.color, datas.lottery);
        }
      }
    });
    return (_ctx, _cache) => {
      const _component_empty = _resolveComponent("empty");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(search, {
        onColors: _unref(colors),
        onOnSubmit: _unref(onSubmit),
        colors: _unref(datas).color
      }, null, 8, ["onColors", "onOnSubmit", "colors"]), _createElementVNode("div", _hoisted_1, [_unref(datas).indexList.length == 0 ? (_openBlock(), _createBlock(_component_empty, {
        key: 0
      })) : (_openBlock(true), _createElementBlock(_Fragment, {
        key: 1
      }, _renderList(_unref(datas).indexList, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          class: "tk-type-box",
          key: index,
          ref_for: true,
          ref_key: "scroll",
          ref: scroll
        }, [item.dataList.length != 0 ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "tk-type-head",
          id: item.letter
        }, _toDisplayString(item.letter), 9, _hoisted_2)) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_3, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.dataList, (items, i) => {
          return _openBlock(), _createElementBlock("li", {
            class: "flex-between",
            key: i,
            onClick: $event => _unref(majorDesc)(items)
          }, [_createElementVNode("span", _hoisted_5, _toDisplayString(items.pictureName), 1), _createElementVNode("span", _hoisted_6, _toDisplayString(items.max_issue), 1)], 8, _hoisted_4);
        }), 128))])])]);
      }), 128)), _createElementVNode("div", _hoisted_7, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(datas).list, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          class: _normalizeClass(index == _unref(activeStep) ? 'tk-type-item acitve' : 'tk-type-item'),
          key: index
        }, [_createElementVNode("a", {
          class: _normalizeClass(index == _unref(activeStep) ? 'tk-type-item acitve' : 'tk-type-item'),
          href: '#' + item
        }, _toDisplayString(item), 11, _hoisted_8)], 2);
      }), 128))])])], 64);
    };
  }
};
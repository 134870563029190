
import serach from '@/views/page/serach.vue';
import notice from '@/views/page/notice.vue';
import image from '@/views/page/image.vue';
import lottery from '@/views/page/lottery/index.vue';
import history from '@/views/page/lottery/history.vue';
import playback from '@/views/page/lottery/playback.vue';
import playDesc from '@/views/page/lottery/playDesc.vue';
import historyDesc from '@/views/page/lottery/historyDesc.vue';



import materials from '@/views/page/materials/index.vue'
import list from '@/views/page/materials/list.vue'
import search from '@/views/page/materials/search.vue'
import details from '@/views/page/materials/details.vue'
import commentsc from '@/views/page/materials/comment.vue'
import materialsHistory from '@/views/page/materials/history.vue';
const pageRoute = [

    {
        path: '/serach',
        name: 'serach',
        component: serach,
        meta: {
            keepAlive: true,
            top:true,
        },
    },
    {
        path: '/notice',
        name: 'notice',
        component: notice
        
    },
    {
        path: '/image',
        name: 'image',
        component: image,
        meta: {
            keepAlive: true,
            top:true,
          },
        
    },
    {
        path: '/lottery',
        name: 'lottery',
        meta: {
            keepAlive: true,
          },
        component: lottery
    },
    {
        path: '/lottery/history',
        name: 'history',
        component: history
    },
    {
        path: '/lottery/playback',
        name: 'playback',
        component: playback,
        meta:{
            keepAlive: true,
            scrollTo:true
        }
    },
    {
        path: '/lottery/playback/details',
        name: 'playDesc',
        component: playDesc
    },
    {
        path: '/lottery/history/details',
        name: 'historyDesc',
        component: historyDesc
    },
    
    
    

    {
        path: '/materials',
        name: 'materials',
        component: materials,  
        meta: {
            keepAlive: true,
            top:true,
        },
    },
    {
        path: '/materials/list',
        name: 'list',
        component: list,
        meta: {
            keepAlive: true,
            top:true,
        },
    },
    {
        path: '/materials/list/search',
        name: 'search',
        component: search,
        meta: {
         
            top:true,
        },
    },
    {
        path: '/materials/list/details',
        name: 'details',
        component: details,
        meta: {
            keepAlive: true,
            top:true,
        },
       
    },
    {
        path: '/materials/list/comment',
        name: 'materialscomments',
        component: commentsc,
        meta:{
            needLogin: true,
            top:true,
        }
    },
    {
        path: '/materials/list/history',
        name: 'materialsHistory',
        component: materialsHistory,
        meta:{
            top:true,
        }
    },

      
    
    
]


export default pageRoute
import serve from './serve'

export function pictureCate(params){//图库
    return serve({
        url:'v1/index/picture',
        method :'get',
        params:params,
    })
}
export function years(params){//图库年份
    return serve({
        url:'v1/index/years_color',
        method :'get',
        params:params,
    })
}

export function yearAll(params){//图库年份
    return serve({
        url:'v1/index/years',
        method :'get',
        params:params,
    })
}


export function swiperImg(params){//首页轮播图
    return serve({
        url:'v1/index/index',
        method :'get',
        params:params,
    })
}

export function getMessage(params){//首页公告
    return serve({
        url:'v1/common/getMessage',
        method :'get',
        params:params,
    })
}
export function cate(params){//图片分类接口
    return serve({
        url:'v1/picture/cate',
        method :'get',
        params:params,
    })
}


export function config(params){//公告信息
    return serve({
        url:'v1/common/config',
        method :'get',
        params:params,
    })
}

export function setMessage(params){//公告信息已读
    return serve({
        url:'v1/common/setMessage',
        method :'post',
        params:params,
    })
}

export function lotteryrecord(params){//开奖详情
    return serve({
        url:'v1/liuhe/record',
        method :'get',
        params:params,
    })
}


export function getMaterial(params){//首页资料
    return serve({
        url:'v1/index/material',
        method :'get',
        params:params,
    })
}

export function advertisementList(params){//详情广告列表
    return serve({
        url:'v1/ad/list',
        method :'get',
        params:params,
    })
}

export function lottery_years(params){//彩种对应年份
    return serve({
        url:'v1/index/lottery_years',
        method :'get',
        params:params,
    })
}
export function lottery_years_color(params){//彩种对应年份
    return serve({
        url:'v1/index/lottery_years_color',
        method :'get',
        params:params,
    })
}


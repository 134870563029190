import serve from './serve'

export function discoveryList(params){//发现列表
    return serve({
        url:'v1/discovery/list',
        method :'get',
        params:params,
    })
}

export function discoveryDetail(params){//发现详情
    return serve({
        url:'v1/discovery/detail',
        method :'get',
        params:params,
    })
}

export function discoveryFollow(params){//发现点赞
    return serve({
        url:'v1/discovery/follow',
        method :'post',
        params:params,
    })
}

export function discoveryCollect(params){//发现点赞
    return serve({
        url:'v1/discovery/collect',
        method :'post',
        params:params,
    })
}

export function createArticle(params){//发现点赞
    return serve({
        url:'v1/discovery/create',
        method :'post',
        params:params,
    })
}

export function temp_cred(params){//发现视频凭证
    return serve({
        url:'v1/common/temp_cred',
        method :'post',
        params:params,
    })
}

export function video(params){//发现视频上传
    return serve({
        url:'v1/common/video',
        method :'post',
        params:params,
    })
}

import tool from '@/views/page/tool/index.vue';
import love from '@/views/page/tool/love.vue';
import bags from '@/views/page/tool/bags.vue';
import shake from '@/views/page/tool/shake.vue';
import sx from '@/views/page/tool/sx.vue';
import bagSearch from '@/views/page/tool/bagSearch.vue';
import rotation from '@/views/page/tool/rotation.vue';
import turntable from '@/views/page/tool/turntable.vue'
import lotteryData from '@/views/page/tool/lotteryData.vue'
import secret from '@/views/page/tool/secret.vue'
import assistant from '@/views/page/tool/assistant.vue'
import notepad from '@/views/page/tool/notepad.vue'

const toolRoute = [
    {
        path: '/tool',
        name: 'tool',
        component: tool,
        meta: {
            keepAlive: true,
          },
    },
    {
        path: '/tool/love',
        name: 'love',
        component: love
    },
    {
        path: '/tool/sx',
        name: 'sx',
        component: sx
    },
    {
        path: '/tool/shake',
        name: 'shake',
        component: shake
    },
    {
        path: '/tool/bags',
        name: 'bags',
        component: bags
    },
    {
        path: '/tool/bags/search',
        name: 'bagSearch',
        component: bagSearch
    },
    {
        path: '/tool/rotation',
        name: 'rotation',
        component: rotation
    },
    {
        path: '/tool/turntable',
        name: 'turntable',
        component: turntable
    },
    {
        path: '/tool/lottery/data',
        name: 'lotteryData',
        component: lotteryData
    },
    {
        path: '/tool/secret',
        name: 'secret',
        component: secret
    },
    {
        path: '/tool/assistant',
        name: 'assistant',
        component: assistant
    },
    {
        path: '/tool/assistant/notepad',
        name: 'notepad',
        component: notepad
    },


    
        
    
]

export default toolRoute
import gallery from '@/views/page/gallery/index.vue';

import analysis from '@/views/page/gallery/analysis.vue';
import comment from '@/views/page/gallery/comment.vue';
import guessing from '@/views/page/gallery/guessing.vue';
import moreType from '@/views/page/gallery/moreType.vue';
import details from '@/views/page/gallery/details.vue';
import jingDetails from '@/views/page/gallery/jingDetails.vue'
import VotRecord from '@/views/page/gallery/VotRecord.vue'

const galleryRoute = [
    {
        path: '/gallery',
        name: 'gallery',
        component: gallery,
        meta: {
            keepAlive: true,
            top:true,
        },
    },
    {
        path: '/gallery/analysis',
        name: 'analysis',
        component: analysis,
        meta: {
            top:true
        },
        
    },
    {
        path: '/gallery/analysis/comment',
        name: 'comments',
        component: comment
    },
    {
        path: '/gallery/analysis/guessing',
        name: 'guessing',
        component: guessing,
        meta:{
            needLogin: true,
            top:true
        }
    },
    {
        path: '/gallery/analysis/guessing/more',
        name: 'moreType',
        component: moreType
    },
    {
        path: '/gallery/analysis/details',
        name: 'galleryDetails',
        component: details,
        meta: {
            top:true
        },
    },
    {
        path: '/gallery/guess/details',
        name: 'jingDetails',
        component: jingDetails,
        meta: {
            top:true
        },
    },
    {
        path: '/gallery/guess/VotRecord',
        name: 'VotRecord',
        component: VotRecord
    },
    
    
]

export default galleryRoute
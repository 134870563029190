import "core-js/modules/es.array.push.js";
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

import { reactive, toRefs, onBeforeMount } from 'vue';
import { useRouter, useRoute } from 'vue-router';
const _withScopeId = n => (_pushScopeId("data-v-7d2daa0b"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "tk-main-materials"
};
const _hoisted_2 = ["onClick"];
export default {
  __name: 'period',
  setup(__props) {
    let data = reactive({
      issue: [],
      title: '',
      activeIndex: 0
    });
    let {
      issue,
      title,
      activeIndex
    } = toRefs(data);
    const router = useRouter();
    const route = useRoute();
    onBeforeMount(() => {
      let issue = JSON.parse(route.query.data);
      data.issue = issue;
      let title = route.query.year;
      let issueNum = route.query.issue;
      data.title = `${title}年第${issueNum}期`;
      issue.forEach((item, index) => {
        if (item.issue == issueNum) {
          data.activeIndex = index;
        }
      });
    });
    let majorDesc = (item, index) => {
      data.title = item.issue;
      data.activeIndex = index;
      router.push({
        path: `/guess`,
        query: {
          issue: JSON.stringify(item),
          activeIndex: index
        }
      });
    };
    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: `${_unref(title)}`
      }, null, 8, ["title"]), _createElementVNode("div", _hoisted_1, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(issue), (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          class: _normalizeClass(_unref(activeIndex) == index ? 'active' : ''),
          onClick: $event => _unref(majorDesc)(item, index),
          key: index
        }, "第" + _toDisplayString(item.issue) + "期", 11, _hoisted_2);
      }), 128))])])], 64);
    };
  }
};
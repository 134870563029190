import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import videoJs from 'video.js';

import { ref, onMounted, onUnmounted } from 'vue';
export default {
  __name: 'videoPlayer',
  props: {
    options: {
      type: Object,
      default: () => ({})
    }
  },
  setup(__props) {
    const props = __props;
    const rVideoPlayer = ref(null);
    let player = ref();
    onMounted(() => {
      player.value = videoJs(rVideoPlayer.value, props.options);
    });
    onUnmounted(() => {
      if (player.value) {
        player.value.dispose();
      }
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("video", {
        ref_key: "rVideoPlayer",
        ref: rVideoPlayer,
        playsinline: "false",
        "webkit-playsinline": "false",
        "data-setup": "{\"fluid\": true}",
        class: "video-js vjs-default-skin",
        style: {
          "width": "100%",
          "height": "222px !important"
        }
      }, null, 512);
    };
  }
};

import headNav from "@/components/headNav.vue";
import empty from '@/components/page/empty.vue';
import navTabsVue from "@/components/page/navTabs.vue";
import swipers from '@/components/page/swipers.vue';
import botton from '@/components/botton.vue';
import major from '@/components/majorBotton.vue';
import prompt from '@/components/prompt.vue'
import back from '@/components/majorBack.vue'
import share from '@/components/page/share.vue';
import lotteryTabs from '@/components/lotteyTabs.vue';
import majorBack from '@/components/majorBack.vue';
import search from '@/components/page/search.vue';
const components = {
    headNav,
    empty,
    navTabsVue,
    swipers,
    botton,
    major,
    prompt,
    back,
    share,
    lotteryTabs,
    majorBack,
    search
}

const component = (app) => {
  Object.keys(components).forEach((key) => {
    app.component(`${key}`, components[key])
  })
}

export default component

import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-b931f026"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["src"];
import { loginChat } from '@/utils/https/register';

import { useRouter } from 'vue-router';
import { ref, onBeforeMount } from 'vue';
export default {
  __name: 'liuhe',
  setup(__props) {
    let router = useRouter();
    let url = ref('');
    onBeforeMount(() => {
      if (localStorage.getItem('chatToken') == null) {
        loginChats();
      } else {
        let token = localStorage.getItem('chatToken');
        let urls = JSON.parse(localStorage.getItem('config')).old_ws_url;
        url.value = `${urls}sp/amzl.html?t=${token}`;
      }
    });
    let loginChats = () => {
      loginChat().then(res => {
        if (res.data.status == 20000) {
          let chat = res.data.data.token;
          localStorage.setItem('chatToken', chat);
          let urls = JSON.parse(localStorage.getItem('config')).old_ws_url;
          url.value = `${urls}sp/amzl.html?t=${chat}`;
        }
      });
    };
    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: "聊天"
      }), _createElementVNode("iframe", {
        src: _unref(url),
        frameborder: "0"
      }, null, 8, _hoisted_1)], 64);
    };
  }
};
import serve from './serve'

export function getUserInfo(params){//获取用户信息
    return serve({
        url:'v1/user/getUserInfo',
        method :'get',
        params:params,
    })
}

export function editUserInfo(params){//修改用户信息
    return serve({
        url:'v1/user/editUserInfo',
        method :'post',
        params:params,
    })
}

export function editUserPass(params){//修改用户信息
    return serve({
        url:'v1/user/editUserPass',
        method :'post',
        params:params,
    })
}

export function addAdvice(params){//用户反馈
    return serve({
        url:'v1/user/addAdvice',
        method :'post',
        params:params,
    })
}


export function getCollect(params){//用户收藏
    return serve({
        url:'v1/user/getCollect',
        method :'get',
        params:params,
    })
}

export function getComment(params){//用户评论
    return serve({
        url:'v1/user/getComment',
        method :'get',
        params:params,
    })
}

export function getFocus(params){//用户关注
    return serve({
        url:'v1/user/getFocus',
        method :'get',
        params:params,
    })
}
export function getFans(params){//我的粉丝
    return serve({
        url:'v1/user/getFans',
        method :'get',
        params:params,
    })
}

export function getFollows(params){//点赞列表
    return serve({
        url:'v1/user/getFollows',
        method :'get',
        params:params,
    })
}

export function getBlackHouse(params){//小黑屋
    return serve({
        url:'v1/user/getBlackHouse',
        method :'get',
        params:params,
    })
}
export function getUserBlackHouse(params){//小黑屋个人记录
    return serve({
        url:'v1/user/getUserBlackHouse',
        method :'get',
        params:params,
    })
}
export function getBlacklist(params){//拉黑列表
    return serve({
        url:'v1/user/getBlacklist',
        method :'get',
        params:params,
    })
}

export function signIn(params){//签到
    return serve({
        url:'v1/user/signIn',
        method :'post',
        params:params,
    })
}

export function getActivityInformation(params){//签到中心详情
    return serve({
        url:'v1/activity/list',
        method :'get',
        params:params,
    })
}

export function activityReceive(params){//领取任务中心的数据
    return serve({
        url:'v1/activity/receive',
        method :'post',
        params:params,
    })
}

export function activityFilling(params){//领取任务中心的数据
    return serve({
        url:'v1/activity/filling',
        method :'get',
        params:params,
    })
}



export function getAdviceList(params){//意见反馈列表
    return serve({
        url:'v1/user/getAdviceList',
        method :'get',
        params:params,
    })
}

export function complaintList(params){//意见反馈列表
    return serve({
        url:'v1/complaint/list',
        method :'get',
        params:params,
    })
}

export function shareList(params){//分享排行榜
    return serve({
        url:'v1/user/shareList',
        method :'get',
        params:params,
    })
}

export function fanList(params){//粉丝排行榜
    return serve({
        url:'v1/user/fanList',
        method :'get',
        params:params,
    })
}

export function rankList(params){//等级排行榜
    return serve({
        url:'v1/user/rankList',
        method :'get',
        params:params,
    })
}

export function goldList(params){//礼物排行榜
    return serve({
        url:'v1/user/goldList',
        method :'get',
        params:params,
    })
}

export function getLevel(params){//礼物排行榜
    return serve({
        url:'v1/user/getLevel',
        method :'get',
        params:params,
    })
}

export function getGrowthScore(params){//礼物排行榜
    return serve({
        url:'v1/user/getGrowthScore',
        method :'get',
        params:params,
    })
}






///充值相关

export function platList(params){//平台列表
    return serve({
        url:'v1/plat/list',
        method :'get',
        params:params,
    })
}

export function platBind(params){//绑定平台
    return serve({
        url:'v1/plat/bind',
        method :'post',
        params:params,
    })
}


export function platUserPlat(params){//用户平台列表
    return serve({
        url:'v1/plat/user_plat',
        method :'get',
        params:params,
    })
}

export function platQuotas(params){//用户平台列表
    return serve({
        url:'v1/plat/quotas',
        method :'get',
        params:params,
    })
}

export function platRecharge(params){//绑定平台
    return serve({
        url:'v1/plat/recharge',
        method :'post',
        params:params,
    })
}

export function platWithdraw(params){//提现
    return serve({
        url:'v1/plat/withdraw',
        method :'post',
        params:params,
    })
}

export function platWithdraw_page(params){//提现页面详情
    return serve({
        url:'v1/plat/withdraw_page',
        method :'get',
        params:params,
    })
}



export function setFundPassword(params){//设置资金密码
    return serve({
        url:'v1/user/setFundPassword',
        method :'post',
        data:params,
    })
}


export function editFundPassword(params){//设置资金密码
    return serve({
        url:'v1/user/editFundPassword',
        method :'post',
        data:params,
    })
}




import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, isRef as _isRef, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-e335e1bc"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "tk-main-details"
};
const _hoisted_2 = {
  class: "cont"
};
const _hoisted_3 = ["src"];
const _hoisted_4 = {
  style: {
    "margin-top": "10px"
  }
};
const _hoisted_5 = {
  class: "tk-swiper",
  style: {
    "margin-bottom": "20px",
    "margin-top": "20px"
  }
};
import loading from '@/components/mode/loading';
import lottery from '@/components/page/lottery.vue';
import more from '@/components/page/more.vue';
import share from '@/components/page/share.vue';
import Dialog from '@/components/mode/message';
import { infoArticle, getUserIndex, follow } from '@/utils/https/materials';
import { setFocus } from '@/utils/https/https';
import { ref } from 'vue';
import axios from 'axios';

import { reactive, toRefs, onBeforeMount, onDeactivated, onActivated, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
export default {
  __name: 'gp',
  setup(__props) {
    let data = reactive({
      show: false,
      id: '',
      //文章id
      articleInfo: {},
      getUserInfo: {},
      guanzhuicno: true,
      //关注
      guanzhuicolor: '#FF1A00',
      //关注
      guanzhuname: '关注',
      shows: false,
      //显示分享页面
      corpusTypeId: '',
      listData: [{
        name: '发表',
        icon: require('../../../assets/image/major/2131.png'),
        path: '/master/release'
      }, {
        name: '六合图库',
        icon: require('../../../assets/image/major/2063.png'),
        path: '/search'
      }, {
        name: '幽默猜测',
        icon: require('../../../assets/image/major/2062.png'),
        path: '/guess'
      }, {
        name: '聊天室',
        icon: require('../../../assets/image/major/2129.png'),
        path: '1'
      }, {
        name: '挑码助手',
        icon: require('../../../assets/image/major/2108.png'),
        path: '/tool/assistant'
      }],
      clientWidth: '',
      datalist: [{
        name: '用户主页',
        icon: require('../../../assets/image/meuser.png')
      }, {
        name: '历史帖子',
        icon: require('../../../assets/image/history.png')
      }, {
        name: '举报',
        icon: require('../../../assets/image/warning.png')
      }],
      gameShow: false,
      type: 0,
      websiteName: JSON.parse(localStorage.getItem('config')) == null ? {
        name: '图库中心',
        login: require('../../../assets/image/logo.png')
      } : JSON.parse(localStorage.getItem('config')),
      https: process.env.VUE_APP_BASE_API,
      forumData: {},
      lotteryType: 5,
      adList: [],
      lotterys: ''
    });
    let {
      lotteryType,
      forumData,
      show,
      shows,
      articleInfo,
      user,
      guanzhuicno,
      guanzhuicolor,
      guanzhuname,
      getUserInfo,
      id,
      listData,
      clientWidth,
      datalist,
      gameShow,
      websiteName,
      https
    } = toRefs(data);
    let router = useRouter();
    let route = useRoute();
    onBeforeMount(() => {
      data.adList = JSON.parse(localStorage.getItem('avList')).adlist;
      data.lotteryType = localStorage.getItem('lotteryType');
      data.id = route.query.id;
      switch (data.lotteryType) {
        case '5':
          data.lotterys = 'am48';
          break;
        case '2':
          data.lotterys = 'am';
          break;
        case '1':
          data.lotterys = 'xg';
          break;
        case '3':
          data.lotterys = 'tw';
          break;
        case '4':
          data.lotterys = 'xjp';
        case '6':
          data.lotterys = 'am48';
          break;
      }
      artinfoFun(data.id, data.lotterys);

      // data.corpusTypeId = route.query.corpusTypeId
      // infoData(data.id,data.corpusTypeId);
      // data.type = route.query.id;
    });
    //数据请求区域
    let infoData = (id, corpusTypeId) => {
      infoArticle({
        id: id,
        corpusTypeId: corpusTypeId
      }).then(res => {
        //帖子详情
        if (res.status != '400') {
          let datas = res.data.data;
          data.articleInfo = datas;
          getUser(datas.user_id);
        } else {
          Dialog(res.data.message, '#3f2860');
        }
      });
    };
    let artinfoFun = (id, lotterys) => {
      window.scrollTo(0, 0);
      let articleData = JSON.parse(sessionStorage.getItem(`gp_list${data.lotteryType}_id${id}`));
      if (articleData == null) {
        loading();
        let url = `https://am.zlapi8.com/api/gpinfo`;
        axios.post(url, {
          id: id,
          type: lotterys
        }).then(res => {
          loading('close');
          let datas = res.data.data;
          data.forumData = datas;
          sessionStorage.setItem(`gp_list${data.lotteryType}_id${id}`, JSON.stringify(datas));
        });
      } else {
        data.forumData = articleData;
      }
    };

    //功能区域
    let majoFun = index => {
      //comment组件的信息数据
      if (index == 0) {
        //点赞
        if (data.articleInfo.isFollow == 0) {
          //判断是否点赞
          data.articleInfo.isFollow = 1;
          Dialog('点赞成功', '#3f2860');
          data.articleInfo.thumbUpCount += 1;
          data.getUserInfo.likes += 1;
        } else {
          data.articleInfo.isFollow = 0;
          data.articleInfo.thumbUpCount -= 1;
          data.getUserInfo.likes -= 1;
          Dialog('已取消点赞', '#3f2860');
        }
        onefollow(data.articleInfo.id);
      } else if (index == 2) {
        data.shows = true;
      }
    };
    let funcMore = index => {
      //更多弹窗里面的信息 0 是用户主页，1是历史记录  2是举报
      if (index == 1) {
        router.push({
          path: `/materials/list/history`,
          query: {
            id: data.articleInfo.id,
            title: data.getUserInfo.nickname,
            userId: data.articleInfo.user_id,
            corpusTypeId: data.corpusTypeId
          }
        });
      } else if (index == 0) {
        router.push({
          path: '/userInfo',
          query: {
            id: data.getUserInfo.id
          }
        });
      } else if (index == 2) {
        router.push({
          path: '/user/report',
          query: {
            typeId: 4,
            id: data.articleInfo.id,
            corpusTypeId: data.corpusTypeId
          }
        });
      }
    };
    let majoruser = id => {
      //跳转用户中心
      router.push({
        path: '/userInfo',
        query: {
          id: id
        }
      });
    };
    let majorTab = item => {
      if (item.name == '聊天室') {
        data.gameShow = true;
      }
    };
    let selecRoom = item => {
      //选择聊天室
      router.push({
        path: '/wechat',
        query: {
          title: item.name,
          id: item.id
        }
      });
    };
    let start = ref(true);
    onDeactivated(() => {
      start.value = false;
    });
    onActivated(() => {
      window.scrollTo(0, 0);
    });
    let contentbox = computed(() => item => {
      //替换
      if (item != undefined) {
        return item.replace(/\n/g, "<br>");
      }
    });
    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      const _component_swipers = _resolveComponent("swipers");
      const _component_major = _resolveComponent("major");
      const _component_majorBack = _resolveComponent("majorBack");
      const _component_wecharList = _resolveComponent("wecharList");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: "详情"
      }, {
        iocn: _withCtx(() => []),
        _: 1
      }), _createElementVNode("div", _hoisted_1, [_createVNode(lottery, {
        lottery: _unref(lotteryType)
      }, null, 8, ["lottery"]), _createElementVNode("div", _hoisted_2, [_createElementVNode("img", {
        src: _unref(forumData).img,
        alt: ""
      }, null, 8, _hoisted_3), _createElementVNode("p", _hoisted_4, "【挂牌】：" + _toDisplayString(_unref(forumData).hdl), 1), _createElementVNode("p", null, "【四字】：" + _toDisplayString(_unref(forumData).ci), 1), _createElementVNode("p", null, "【火烧】：" + _toDisplayString(_unref(forumData).huoshao), 1), _createElementVNode("p", null, "【门数】：" + _toDisplayString(_unref(forumData).men), 1), _createElementVNode("p", null, "【六肖】：" + _toDisplayString(_unref(forumData)['6xiao']), 1), _createElementVNode("p", null, "【词目】：" + _toDisplayString(_unref(forumData).ci), 1), _createElementVNode("p", null, "【拼音】：" + _toDisplayString(_unref(forumData).pinyin), 1), _createElementVNode("p", null, "【解释】：" + _toDisplayString(_unref(forumData).jieshi), 1), _createElementVNode("p", null, "【出自】：" + _toDisplayString(_unref(forumData).chuzi), 1), _createElementVNode("p", null, "【示例】：" + _toDisplayString(_unref(forumData).shili), 1), _createElementVNode("p", null, "【语法】：" + _toDisplayString(_unref(forumData).yufa), 1)]), _unref(forumData).nextid != null ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "next_page tk-next",
        onClick: _cache[0] || (_cache[0] = $event => _unref(artinfoFun)(_unref(forumData).nextid, _unref(data).lotterys))
      }, "上一篇：" + _toDisplayString(_unref(forumData).nextti), 1)) : _createCommentVNode("", true), _unref(forumData).preid != null ? (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: "up_page tk-next",
        onClick: _cache[1] || (_cache[1] = $event => _unref(artinfoFun)(_unref(forumData).preid, _unref(data).lotterys))
      }, "下一篇：" + _toDisplayString(_unref(forumData).preti), 1)) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_5, [_createVNode(_component_swipers, {
        swiperImg: _unref(data).adList
      }, null, 8, ["swiperImg"])])]), _createVNode(_component_major, {
        onMajorTab: _unref(majorTab),
        listData: _unref(listData)
      }, null, 8, ["onMajorTab", "listData"]), _createVNode(_component_majorBack, {
        x: "11d",
        r: "10px",
        y: '62%'
      }), _createVNode(more, {
        show: _unref(show),
        "onUpdate:show": _cache[2] || (_cache[2] = $event => _isRef(show) ? show.value = $event : show = $event),
        onMajorMore: _unref(funcMore),
        datalist: _unref(datalist)
      }, null, 8, ["show", "onMajorMore", "datalist"]), _createVNode(share, {
        shows: _unref(shows),
        "onUpdate:shows": _cache[3] || (_cache[3] = $event => _isRef(shows) ? shows.value = $event : shows = $event)
      }, null, 8, ["shows"]), _createVNode(_component_wecharList, {
        show: _unref(gameShow),
        "onUpdate:show": _cache[4] || (_cache[4] = $event => _isRef(gameShow) ? gameShow.value = $event : gameShow = $event),
        onSelecRoom: _unref(selecRoom)
      }, null, 8, ["show", "onSelecRoom"])], 64);
    };
  }
};
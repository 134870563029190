import { createStore } from 'vuex'
export default createStore({
  state: {
    lotteryType:localStorage.getItem('lotteryType'),
    videoList:[],
    msg:'0',
    release:false,
    openLotteryList:[],
    advertisementList:[],
    imagesb:true,
    search:true,
    confing: JSON.parse(localStorage.getItem('config')),
  },
  getters: {
  },
  mutations: {
    upLotteryCont(state,num){
      state.lotteryType = num ;
      localStorage.setItem('lotteryType',num)
    },
    upvideoCont(state,num){
      state.videoList = num ;
    },
    msgadd(state,num){
      state.msg = num ;
    },
    exidRelease(state,num){
      state.release = num ;
    },
    handleLottery(state,num){
      state.openLotteryList = num
    },
    handleAdvertisemen(state,num){
      state.advertisementList = num
    },
    handleImgs(state,num){
      state.imagesb = num
    },
    handleSearch(state,num){
      state.search = num
    },
    handleConfing(state,num){
      state.confing = num
    },
  
  },
  actions: {
  },
  modules: {
  }
})

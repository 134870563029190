import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '../../assets/image/greed.gif';
import _imports_1 from '../../assets/image/jiazai.gif';
const _withScopeId = n => (_pushScopeId("data-v-1f500180"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "tk-prompt"
};
const _hoisted_2 = {
  class: "tk-prompt-list"
};
const _hoisted_3 = {
  key: 0,
  src: _imports_0,
  alt: ""
};
const _hoisted_4 = {
  key: 1,
  src: _imports_1,
  alt: ""
};
export default {
  __name: 'message',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: '#fff'
    },
    message: {
      type: String,
      default: ''
    }
  },
  emits: ['update:show'],
  setup(__props, {
    emit
  }) {
    const por = __props;
    return (_ctx, _cache) => {
      return __props.show ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
        class: "tk-item flex-between",
        style: _normalizeStyle({
          'background': __props.color
        })
      }, [_createElementVNode("p", {
        style: _normalizeStyle({
          'color': __props.color != '#fff' ? '#fff' : '#3f2860'
        })
      }, _toDisplayString(__props.message), 5), _createElementVNode("div", {
        class: "tail",
        style: _normalizeStyle({
          'background': __props.color != '#fff' ? '#fff' : '#3f2860'
        })
      }, [__props.color != '#fff' ? (_openBlock(), _createElementBlock("img", _hoisted_3)) : (_openBlock(), _createElementBlock("img", _hoisted_4))], 4)], 4)])])) : _createCommentVNode("", true);
    };
  }
};
export default {
  name: 'App',
  mounted: function () {
    var _tish = this;
    let confing = JSON.parse(localStorage.getItem('config'));
    let xam_live = confing.xam_live; //香港直播源

    this.np = new NodePlayer();
    this.np.setView("video");
    this.np.audioResume();
    this.np.setKeepScreenOn();
    this.np.on("stats", s => {});
    this.np.on("error", s => {
      setTimeout(() => {
        _tish.np.start(xam_live);
      }, 3000);
    });
    this.np.on("start", s => {});
    setTimeout(() => {
      _tish.np.start(xam_live);
    }, 3000);
  },
  deactivated() {
    this.np.stop();
  },
  beforeUnmount() {
    this.np.stop();
  },
  methods: {}
};
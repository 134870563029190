import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

import { reactive, toRefs } from 'vue';
const _withScopeId = n => (_pushScopeId("data-v-9a14b2ca"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "tk-container-wrap"
};
const _hoisted_2 = {
  class: "tk-top-wrap"
};
const _hoisted_3 = {
  class: "tk-wrap-title"
};
const _hoisted_4 = {
  class: "tk-wrap"
};
export default {
  __name: 'jiaqin',
  setup(__props) {
    let data = reactive({
      codeData: [{
        title: '家禽',
        data: ['牛、', '马、', '羊、', '鸡、', '狗、', '猪']
      }, {
        title: '野兽',
        data: ['鼠、', '虎、', '兔、', '龙、', '蛇、', '猴']
      }, {
        title: '吉美',
        data: ['兔、', '龙、', '蛇、', '马、', '羊、', '鸡']
      }, {
        title: '凶丑',
        data: ['鼠、', '牛、', '虎、', '猴、', '狗、', '猪']
      }, {
        title: '阴性',
        data: ['鼠、', '龙、', '蛇、', '马、', '狗、', '猪']
      }, {
        title: '阳性',
        data: ['牛、', '虎、', '兔、', '羊、', '猴、', '鸡']
      }, {
        title: '单笔',
        data: ['鼠、', '龙、', '马、', '蛇、', '鸡、', '猪']
      }, {
        title: '双笔',
        data: ['虎、', '猴、', '狗、', '兔、', '羊、', '牛']
      }, {
        title: '天肖',
        data: ['兔、', '马、', '猴、', '猪、', '牛、', '龙']
      }, {
        title: '地肖',
        data: ['蛇、', '羊、', '鸡、', '狗、', '鼠、', '虎']
      }, {
        title: '白边',
        data: ['鼠、', '牛、', '虎、', '鸡、', '狗、', '猪']
      }, {
        title: '黑中',
        data: ['兔、', '龙、', '蛇、', '马、', '羊、', '猴']
      }, {
        title: '女肖',
        data: ['兔、', '蛇、', '羊、', '鸡、', '猪(五宫肖)']
      }, {
        title: '男肖',
        data: ['鼠、', '牛、', '虎、', '龙、', '马、', '猴、', '狗']
      }, {
        title: '三合',
        data: ['鼠龙猴、', '牛蛇鸡、', '虎马狗、', '兔羊猪']
      }, {
        title: '六合',
        data: ['鼠牛、', '龙鸡、', '虎猪、', '蛇猴、', '兔狗、', '马羊']
      }]
    });
    let {
      codeData
    } = toRefs(data);
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(codeData), (item, index) => {
        return _openBlock(), _createElementBlock("ul", {
          key: index
        }, [_createElementVNode("li", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString(item.title), 1), _createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.data, (items, indexs) => {
          return _openBlock(), _createElementBlock("p", {
            key: indexs
          }, _toDisplayString(items), 1);
        }), 128))])])]);
      }), 128))]);
    };
  }
};
import serve from './serve';

export function roomList(params){//获取房间列表
    return serve({
        url:'v1/room/list',
        method :'get',
        params:params,
    })
}


export function roomRecord(params){//获取记录
    return serve({
        url:'v1/room/record',
        method :'post',
        params:params,
    })
}

export function roomJoin(params){//加入房间
    return serve({
        url:'v1/room/join',
        method :'post',
        params:params,
    })
}

export function roomChat(params){//加入房间
    return serve({
        url:'v1/room/chat',
        method :'post',
        params:params,
    })
}







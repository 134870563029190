import statistics from '@/views/page/statistics/index.vue';


const statisticsRoute = [
    {
        path: '/statistics',
        name: 'statistics',
        component: statistics,
        
    },

    

]

export default statisticsRoute
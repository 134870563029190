import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-bdf4ce7c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "tk-picker"
};
import { onBeforeMount, reactive } from "vue";
export default {
  __name: 'picker',
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  emits: ['onConfirm'],
  setup(__props, {
    emit: emits
  }) {
    const props = __props;
    let data = reactive({
      columns: [{
        text: '2024',
        value: '2024'
      }, {
        text: '2024',
        value: '2024'
      }, {
        text: '2024',
        value: '2024'
      }, {
        text: '2024',
        value: '2024'
      }]
    });
    onBeforeMount(() => {
      let year = JSON.parse(localStorage.getItem('year'));
      data.columns.forEach((item, index) => {
        item.text = year[index];
        item.value = year[index];
      });
    });
    let onConfirm = value => {
      let data = value.selectedValues[0];
      emits('update:show', false);
      emits('onConfirm', data);
    };
    let onCancel = () => {
      emits('update:show', false);
    };
    return (_ctx, _cache) => {
      const _component_van_picker = _resolveComponent("van-picker");
      return __props.show ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "tk-picker-main",
        onClick: _cache[0] || (_cache[0] = _withModifiers((...args) => _unref(onCancel) && _unref(onCancel)(...args), ["stop"]))
      }, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_van_picker, {
        title: "选择年份",
        columns: _unref(data).columns,
        onConfirm: _unref(onConfirm),
        onCancel: _unref(onCancel)
      }, null, 8, ["columns", "onConfirm", "onCancel"])])])) : _createCommentVNode("", true);
    };
  }
};